<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import AgencyBooks from "@/components/accounting/accounting-agency/agency-books/index.vue";
import AgencyContacts from "@/components/accounting/accounting-agency/agency-contacts/index.vue";
import PaymentReferences from "@/components/accounting/accounting-agency/payment-references/index.vue";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.CONT.CONA",
      tabs: [
        { name: "AgencyBooks", text: "Ricerca Movimenti" },
        { name: "AgencyContacts", text: "Ricerca Anagrafiche" },
        { name: "PaymentReferences", text: "Ricerca Causale" },
      ],
      tabsGroup: "Management",
      bcs: [
        {
          text: "Contabilità",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Contabilità",
          path: "",
          level: 1,
        },
        {
          text: "Contabilità Agenzia",
          path: "",
          level: 2,
        },
        {
          text: "Ricerca Movimenti",
          path: "module.CONT.CONA",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3,
      selected: "AgencyBooks",
      tabIndex: 0,
    };
  },
  components: {
    AgencyBooks,
    AgencyContacts,
    PaymentReferences,
  },
};
</script>
