<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
          @update="onUpdate"
        >
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import DetailsCard from "@/components/DetailsCard";
import { joinValues } from "@/utils/transforms";

export default {
  name: "General",
  mixins: [FormMixin, ShowMixin],
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "agency_book",
      allRepositories: ["agency_book"],
      mandatoryFields: {},
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        agency_book: {
          "treasury.default": "Cassa Movimentata",
          "sector.default": "Comparto Contabile",
          "broker.default": "Produttore Associato",
          "payment_reference.default": "Causale Movimento",
        },
        agency_contact: {
          agency_contacts: "Anagrafica Associata",
        },
      },
      detailFields: {
        scheda_contatto: {
          header: "Dati Principali",
          edit: false,
          repository: "agency_book",
          fields: [
            {
              label: this.getDictionary(
                "invoice_protocol_number",
                "agency_book"
              ),
              value: "invoice_protocol_number",
            },
            {
              label: this.getDictionary(
                "attribute_CLI_value",
                "agency_contact"
              ),
              value: "attribute_SURN_value",
              virtual: (detail) => {
                return detail?.agency_contacts[0]?.attributables.SURN;
              },
            },
            {
              label: this.getDictionary(
                "attribute_SUP_value",
                "agency_contact"
              ),
              value: "attribute_CNAM_value",
              virtual: (detail) => {
                return detail?.agency_contacts[0]?.attributables.CNAM;
              },
            },
            {
              label: this.getDictionary("title", "sector"),
              value: "sector",
              virtual: (detail) => {
                return this.joinValues([
                  detail.sector?.code,
                  detail.sector?.title,
                ]);
              },
            },
            {
              label: this.getDictionary("broker", "agency_book"),
              value: "brokers.title",
            },
            {
              label: this.getDictionary(
                "payment_reference_code",
                "agency_book"
              ),
              value: "payment_reference.code",
            },
            {
              label: this.getDictionary(
                "additional_description",
                "agency_book"
              ),
              value: "additional_description",
            },
            {
              label: this.getDictionary("gross", "agency_book"),
              value: "gross",
              type: "currency",
              thClass: "text-right",
              thStyle: {
                "text-align": "right",
              },
              tdClass: "text-right",
            },
          ],
        },
      },
    };
  },
  methods: {
    joinValues,
    fetchForm() {
      let queryString = `relations/bySector/byBroker/byPaymentReference/byAgencyContact`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          const cl = this.fetchEditForm(this.repository, this.resourceId);
          const ac = this.fetchCreateForm("agency_contact");
          Promise.all([cl, ac])
            .then(() => {
              this.initDetailCardFormData();
              this.isLoading = false;
            })
            .catch((error) => {
              this.form = {};
              console.error(error);
              this.isLoading = false;
            });
        })
        .catch((error) => {
          this.form = {};
          console.error(error);
          this.isLoading = false;
        });
    },
    onReload() {
      this.isLoading = true;
      this.fetch();
    },
    onUpdate(form, repository) {
      const Repo = RepositoryFactory.get(repository);

      let payload = { ...this.mandatoryFields[repository] };
      for (const f in payload) {
        payload[f] =
          typeof this.details[f] === "object" && !Array.isArray(this.details[f])
            ? this.details[f].value
            : this.details[f];
      }

      payload = { ...payload, ...form };
      return Repo.update(this.resourceId, payload).then(() => {
        this.onReload();
      });
    },
  },
  computed: {},
  mounted() {
    this.isLoading = true;
    this.fetchForm();
  },
};
</script>

<style scoped lang="scss"></style>
