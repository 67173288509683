<template>
  <div class="mt-1">
    <b-form @submit.prevent="onSearchCustom(filterName)">
      <b-card class="filter">
        <b-row>
          <div class="col-md-3">
            <base-datepicker
              name="registrazione_da"
              label="Data registrazione da"
              v-model="filter.byCalendar.to"
            />
          </div>
          <div class="col-md-3">
            <base-datepicker
              name="registrazione_a"
              label="Data registrazione a"
              v-model="filter.byCalendar.from"
            />
          </div>
          <div class="form-group col-md-4 align-self-end">
            <b-button type="submit" variant="lisaweb" size="sm">Cerca</b-button>
            <b-button
              class="btn-reset"
              type="button"
              variant="lisaweb"
              size="sm"
              @click="onClearFilter(filterName)"
              >Reset</b-button
            >
          </div>
        </b-row>
      </b-card>
    </b-form>
    <agency-books-table
      :fields="fields"
      :filterOn="{
        byRelations: ['byBroker', 'byTreasury', 'bySector'],
      }"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      noActions
      noInnerWidth
      includeBottomRow
      noPagination
      noInputPaginator
    >
    </agency-books-table>
  </div>
</template>

<script>
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { mapActions } from "vuex";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  mixins: [CustomFiltersMixin, DetailTabAutofetchTableMixin],
  name: "AgencyBook",
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterAgencyBooksAgencyContactsDetail",
      repository: "agency_book",
      resource: "agency_books",
      tableRef: "AgencyBooksTableRef",
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "agency_book"),
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "book_date",
        },
        {
          key: "effective_date",
          label: this.getDictionary("effective_date", "agency_book"),
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "effective_date",
        },
        {
          key: "agencybook_type.text",
          label: this.getDictionary("agencybook_type", "agency_book"),
          sortable: true,
          sortKey: "agencybook_type.text",
        },
        {
          key: "payment_reference.code",
          label: this.getDictionary("payment_reference_id", "agency_book"),
          sortable: true,
          sortKey: "payment_reference.code",
        },
        {
          key: "additional_description",
          label: this.getDictionary("additional_description", "agency_book"),
          sortable: true,
          sortKey: "additional_description",
        },
        {
          key: "sector_info",
          label: this.getDictionary("title", "sector"),
        },
        {
          key: "treasury_info",
          label: this.getDictionary("title", "treasury"),
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "agency_book"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
          sortable: true,
          sortKey: "gross",
        },
      ],
    };
  },
  components: {
    BaseDatepicker,
    AgencyBooksTable: () => import("@/components/tables/AgencyBooks"),
  },
  methods: {
    initFilter() {
      let init = {
        byAgencyContact: { id: this.resourceId },
        byCalendar: {
          from: null,
          to: null,
        },
      };
      return init;
    },
    onSearchCustom(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  props: {
    resourceId: Number,
  },
};
</script>

<style scoped></style>
