<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in dtlFlds"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import DetailsCard from "@/components/DetailsCard";
import BaseIcon from "@/components/BaseIcon";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      repository: "agency_contact",
      isLoading: true,
      status_agency_contact: 0,
      details: null,
      detailFields: {
        status_agency_contact_0: {
          scheda_contatto: {
            header: "Scheda Contatto",
            repository: "agency_contact",
            fields: [
              {
                label: this.getDictionary(
                  "attribute_SURN_value",
                  "agency_contact"
                ),
                value: "attributables.SURN",
              },
              {
                label: this.getDictionary(
                  "attribute_NAME_value",
                  "agency_contact"
                ),
                value: "attributables.NAME",
              },
              {
                label: this.getDictionary(
                  "attribute_NINO_value",
                  "agency_contact"
                ),
                value: "attributables.NINO",
              },
              {
                label: this.getDictionary(
                  "attribute_CLI_value",
                  "agency_contact"
                ),
                value: "attribute_CLI_value",
                virtual: (detail) => {
                  return detail?.attributables.CLI === "Y" ? "Si" : "No";
                },
              },
              {
                label: this.getDictionary(
                  "attribute_SUP_value",
                  "agency_contact"
                ),
                value: "attribute_SUP_value",
                virtual: (detail) => {
                  return detail?.attributables.SUP === "Y" ? "Si" : "No";
                },
              },
            ],
          },
        },
        status_agency_contact_1: {
          scheda_contatto: {
            header: "Scheda Contatto",
            repository: "agency_contact",
            fields: [
              {
                label: this.getDictionary(
                  "attribute_CNAM_value",
                  "agency_contact"
                ),
                value: "attributables.CNAM",
              },
              {
                label: this.getDictionary(
                  "attribute_CREG_value",
                  "agency_contact"
                ),
                value: "attributables.CREG",
              },
              {
                label: this.getDictionary(
                  "attribute_CLI_value",
                  "agency_contact"
                ),
                value: "attribute_CLI_value",
                virtual: (detail) => {
                  return detail?.attributables.CLI === "Y" ? "Si" : "No";
                },
              },
              {
                label: this.getDictionary(
                  "attribute_SUP_value",
                  "agency_contact"
                ),
                value: "attribute_SUP_value",
                virtual: (detail) => {
                  return detail?.attributables.SUP === "Y" ? "Si" : "No";
                },
              },
            ],
          },
        },
        scheda_recapito: {
          header: "Recapiti",
          repository: "address_book",
          fields: [
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "attributables.CELL",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PHON_value", "address_book"),
              value: "attributables.PHON",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_FAX_value", "address_book"),
              value: "attributables.FAX",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "attributables.MAIL",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PEC_value", "address_book"),
              value: "attributables.PEC",
              relation: "address_books",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_WEB_value", "address_book"),
              value: "attributables.WEB",
              relation: "address_books",
              status: 0,
            },
          ],
        },
        scheda_indirizzo: {
          header: "Indirizzo",
          repository: "correspondence",
          fields: [
            {
              label: this.getDictionary(
                "attribute_NAME2_value",
                "correspondence"
              ),
              value: "attributables.NAME2",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_ADDR_value",
                "correspondence"
              ),
              value: "attributables.ADDR",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY2_value",
                "correspondence"
              ),
              value: "attributables.CITY2",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary("province"),
              value: "attributables.CITY",
              relation: "correspondences",
              virtual: "PROVINCE",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_ZIPC_value",
                "correspondence"
              ),
              value: "attributables.ZIPC",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              virtual: "NATION",
              relation: "correspondences",
              status: 0,
            },
            {
              label: `${this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              )} estera`,
              value: "attributables.NOR",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
          ],
        },
      },
    };
  },
  methods: {
    fetchForm() {
      this.isLoading = true;
      let queryString = `relations/byAddressBook/byCorrespondence`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          //Set Detail Card Data
          this.details = data;
          //Handle agency_contacts status
          this.status_agency_contact = data.attributables.NAME ? 0 : 1;

          if (!(data.correspondences && data.correspondences.length)) {
            this.$delete(this.detailFields, "scheda_indirizzo");
          }
          if (!(data.address_books && data.address_books.length)) {
            this.$delete(this.detailFields, "scheda_recapito");
          }
          this.initDetailCardFormData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
  },
  computed: {
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
    dtlFlds() {
      let { status_agency_contact_0, status_agency_contact_1, ...rest } =
        this.detailFields;
      let c = this.status_agency_contact
        ? status_agency_contact_1
        : status_agency_contact_0;
      let r = { ...c, ...rest };
      return r;
    },
  },
  mounted() {
    this.fetchForm();
  },
};
</script>
